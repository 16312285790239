import React, { FC, useContext, useRef, useState, useEffect } from "react";
import { graphql, Link } from "gatsby";
import { WavySection } from "../components/wavy-section";
import {
  colors,
  ySpaceRem,
  xSpaceRem,
  fontStacks,
  targetBaseFontSizePx,
} from "../global-style";
import { LayoutContext } from "../components/layout";
import { Center } from "../components/center";
import { StoriesPageQuery } from "../../graphql-types";
import { Grid } from "../components/grid";
import { Footer } from "../components/footer";
import { StoryPreview } from "../components/story-preview";
import { css } from "styled-components";
import { SEO } from "../components/seo";
import { intersection } from "lodash";

export const query = graphql`
  query StoriesPage {
    wp {
      page(id: "verhalen/", idType: URI) {
        seo {
          ...SEO
        }
      }

      stories(first: 9999) {
        edges {
          node {
            ...Story
          }
        }
      }

      climateThemes {
        edges {
          node {
            title
          }
        }
      }
    }
  }
`;

const StoriesPage: FC<{ data: StoriesPageQuery }> = props => {
  const { remInPx, vwInPx, waveClearance } = useContext(LayoutContext);
  const pageTitleRef = useRef<HTMLHeadingElement>(null);

  const [climateThemeFilter, setClimateThemeFilter] = useState<string[]>([]);

  useEffect(() => {
    if (
      climateThemeFilter.length === props.data.wp.climateThemes?.edges?.length
    ) {
      setClimateThemeFilter([]);
    }
  }, [climateThemeFilter]);

  return (
    <>
      <SEO {...props.data.wp.page?.seo} />

      <WavySection
        backgroundColor={colors.mediumBlue}
        style={{
          color: colors.white,
          paddingTop: `calc(${waveClearance} + ${2 * ySpaceRem}rem)`,
          paddingBottom: `${ySpaceRem}rem`,
          transition: "padding 0.25s ease",
        }}
      >
        <Center>
          <div style={{ fontSize: "1.1rem" }}>
            <Link to="/" style={{ textDecoration: "underline" }}>
              Home
            </Link>{" "}
            > Verhalen
          </div>
        </Center>
      </WavySection>

      <div
        style={{
          paddingTop: `${1.5 * ySpaceRem}rem`,
          paddingBottom: `calc(${1.5 * ySpaceRem}rem + ${waveClearance})`,
        }}
      >
        <Center>
          <div style={{ maxWidth: `${32 * xSpaceRem}rem` }}>
            <div
              style={{
                ...(pageTitleRef.current &&
                  (() => {
                    const paddingYRem =
                      ySpaceRem / 2 -
                      ((pageTitleRef.current.getBoundingClientRect().height /
                        remInPx) %
                        (ySpaceRem / 2));

                    return {
                      paddingTop: `${0.5 * paddingYRem}rem`,
                      paddingBottom: `${0.5 * paddingYRem}rem`,
                    };
                  })()),
                transition: "padding 0.25s ease",
                marginBottom: `${0.5 * ySpaceRem}rem`,
              }}
            >
              <h1
                ref={pageTitleRef}
                style={{
                  fontFamily: fontStacks.cubano,
                }}
                css={(() => {
                  const minFontSizeRem = 2;
                  const maxFontSizeRem = 2.8888;
                  const vwRangeStartPx = 500;

                  return css`
                    font-size: ${(minFontSizeRem *
                      (remInPx || targetBaseFontSizePx)) /
                      (vwRangeStartPx / 100)}vw;
                    line-height: 1;

                    @media (max-width: ${vwRangeStartPx}px) {
                      font-size: ${minFontSizeRem}rem;
                    }

                    @media (min-width: ${vwRangeStartPx *
                        (maxFontSizeRem / minFontSizeRem)}px) {
                      font-size: ${maxFontSizeRem}rem;
                    }
                  `;
                })()}
              >
                Verhalen
              </h1>
            </div>

            <p
              style={{
                fontSize: "1.1rem",
                marginBottom: `${1 * ySpaceRem}rem`,
              }}
            >
              Ondergelopen straten door piekbuien, verzakkingen door
              bodemdaling, mislukte oogsten door wateroverlast op de akkers. Dit
              zijn problemen die steeds vaker voorkomen door de effecten van de
              klimaatverandering. Hier kunt u verhalen lezen over situaties die
              zich voordoen in uw regio en welke maatregelen er zijn genomen om
              de overlast te beperken.
            </p>

            <div
              style={{
                marginBottom: `${1 * ySpaceRem}rem`,
                padding: `${0.5 * ySpaceRem}rem ${0.5 * xSpaceRem}rem`,
                backgroundColor: colors.lightestGrey,
                overflow: "hidden",
              }}
            >
              <h3
                style={{
                  paddingBottom: `${0.5 * ySpaceRem}rem`,
                  textAlign: "center",
                }}
              >
                Filter verhalen per klimaatthema
              </h3>

              <div
                style={{
                  overflow: "hidden",
                  backgroundColor: colors.white,
                  color: colors.black,
                  padding: `${0.5 * ySpaceRem}rem ${0.5 * xSpaceRem}rem`,
                }}
              >
                <ul
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    margin: `${-0.5 * ySpaceRem}rem ${-0.5 * xSpaceRem}rem`,
                  }}
                >
                  <li
                    style={{
                      margin: `${0.5 * ySpaceRem}rem ${0.5 * xSpaceRem}rem`,
                    }}
                  >
                    <label style={{ display: "flex", alignItems: "center" }}>
                      <input
                        type="checkbox"
                        onChange={() => setClimateThemeFilter([])}
                        checked={
                          climateThemeFilter.length === 0 ||
                          climateThemeFilter.length ===
                            props.data.wp.climateThemes?.edges?.length
                        }
                      />

                      <em style={{ marginLeft: `${0.25 * xSpaceRem}rem` }}>
                        Alle verhalen
                      </em>
                    </label>
                  </li>

                  {props.data.wp.climateThemes?.edges?.map(edge => (
                    <li
                      style={{
                        margin: `${0.5 * ySpaceRem}rem ${0.5 * xSpaceRem}rem`,
                      }}
                    >
                      <label style={{ display: "flex", alignItems: "center" }}>
                        <input
                          type="checkbox"
                          checked={climateThemeFilter.includes(
                            edge?.node?.title as string
                          )}
                          onChange={e => {
                            if (e.target.checked) {
                              setClimateThemeFilter([
                                ...climateThemeFilter,
                                edge?.node?.title as string,
                              ]);
                            } else {
                              setClimateThemeFilter(climateThemeFilter =>
                                climateThemeFilter.filter(
                                  climateTheme =>
                                    climateTheme !== edge?.node?.title
                                )
                              );
                            }
                          }}
                        />

                        <p style={{ marginLeft: `${0.25 * xSpaceRem}rem` }}>
                          {edge?.node?.title}
                        </p>
                      </label>
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            <Grid
              style={{
                marginBottom: `${0.5 * ySpaceRem}rem`,
                gridGap: `${2 * ySpaceRem}rem ${1 * xSpaceRem}rem`,
              }}
            >
              {props.data.wp.stories?.edges
                ?.filter(
                  edge =>
                    climateThemeFilter.length === 0 ||
                    intersection(
                      climateThemeFilter,
                      edge?.node?.storyCustomFields?.climateThemesStories?.map(
                        edge => edge?.title
                      ) as string[]
                    ).length > 0
                )
                .map(
                  (edge, i) =>
                    edge?.node && <StoryPreview key={i} story={edge.node} />
                )}
            </Grid>
          </div>
        </Center>
      </div>

      <Footer backgroundColor={colors.lightestGrey} />
    </>
  );
};

export default StoriesPage;
